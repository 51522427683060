import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import './animation.min.css';
import './index.css'
  

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const Login = React.lazy(() => import('./views/Login'));
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const PrivateRoute = React.lazy(() => import('./containers/DefaultLayout/PrivateRoute.jsx'));

class App extends Component {

  render() {

    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <PrivateRoute path="/" component={DefaultLayout}  />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
